@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
body {
  background-color: #282c34;
  color: #f1f1f1;
  font-family: 'Lato', sans-serif; }

strong {
  font-weight: 900; }

.App {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1200px) {
    .App {
      flex-direction: row;
      justify-content: center; } }

.block, .intro, .chart-wrap, .copyright, .default-brakes, .mixmatch {
  background: #14161a;
  padding: 1rem 1.5rem;
  border-radius: .4rem;
  margin-bottom: 0.5rem; }

@media only screen and (min-width: 800px) {
  .sidebar p {
    max-width: 280px; } }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .7rem;
  line-height: 1; }

a {
  color: #7FC6A6;
  text-decoration: none;
  font-weight: 700;
  transition: all .2s ease; }
  a:hover {
    color: #5cb68d; }

p {
  margin-top: 0;
  line-height: 1.4;
  margin-bottom: 1rem; }

.left {
  margin: 0 0.5rem; }
  @media only screen and (min-width: 1200px) {
    .left {
      width: 20%;
      margin: 0.5rem; } }

.right {
  margin: 0 0.5rem; }
  @media only screen and (min-width: 800px) {
    .right {
      display: flex; } }
  @media only screen and (min-width: 1200px) {
    .right {
      width: 80%;
      margin: 0.5rem 0.5rem 0.5rem 0; } }

.intro {
  overflow: hidden; }
  .intro h5 {
    margin-bottom: .3rem;
    color: #979797; }
  .intro .small {
    font-size: .8rem;
    color: #979797;
    margin-bottom: 0;
    padding-bottom: 1rem; }
  .intro .hyd {
    color: #5c6bc0; }
  .intro .mec {
    color: #26a69a; }
  .intro .total {
    color: #ff7043; }
  .intro .peak {
    color: #00796b; }
  .intro .weight {
    color: #fbc02d; }
  .intro .expand {
    background: #000;
    cursor: pointer;
    font-size: 70%;
    margin: 0 -1.5rem -1rem -1.5rem;
    text-align: center;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .intro .expand span {
      margin-right: 1rem; }
    .intro .expand svg {
      width: 1rem;
      transition: all .2s ease; }
    .intro .expand.collapsed svg {
      transform: rotate(180deg); }

.chart-wrap {
  margin-left: 0;
  margin-right: 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .chart-wrap .selector {
    display: inline-flex; }
  @media only screen and (min-width: 800px) {
    .chart-wrap {
      margin-right: 0.5rem; } }

.copyright {
  position: relative;
  overflow: hidden; }
  .copyright p {
    font-size: .8rem;
    margin-bottom: .5rem; }
  .copyright.hide-for-large {
    margin: 0 0.5rem 0.5rem 0.5rem; }

.custom-tooltip {
  background: #282c34;
  border-radius: .4rem;
  padding: 1rem 2rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3); }
  .custom-tooltip h4 {
    margin-top: .5rem; }
  .custom-tooltip h6 {
    margin: 0;
    color: #8992a4; }
  .custom-tooltip p {
    margin: 0; }
  .custom-tooltip .item-wrap {
    display: flex;
    align-items: flex-start;
    margin: 0 0 .5rem 0; }
    .custom-tooltip .item-wrap .color {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
      border-radius: 3px; }

.default-brakes > .selector-wrap {
  min-height: 8rem;
  position: relative; }

.mixmatch {
  border: 3px solid #7FC6A6;
  flex: 1 1; }
  .mixmatch .selector-wrap {
    margin: 0.5rem 0; }
    .mixmatch .selector-wrap:first-of-type {
      margin: 0; }

.dropdown-select {
  padding: .5rem .6rem;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start; }
  .dropdown-select input {
    background: none;
    border: 0;
    flex: 1 1;
    width: 80%; }
  .dropdown-select:hover {
    background: #14161a; }
  .dropdown-select .fluid {
    margin-left: 1rem;
    border-radius: 3px;
    padding: .1rem .2rem;
    color: #14161a;
    font-size: .6rem; }
    .dropdown-select .fluid.DOT {
      background: #ffee58; }
    .dropdown-select .fluid.Mineral {
      background: #42a5f5; }
  .dropdown-select .label {
    width: 8rem;
    line-height: 1;
    color: #f1f1f1;
    padding: 0;
    font-size: 0.9rem; }
  .dropdown-select.selected {
    background: #7FC6A6; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #f1f1f1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f1f1f1; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f1f1f1; }

::placeholder {
  /* Most modern browsers support this now. */
  color: #f1f1f1; }

.react-dropdown-select {
  height: 100%; }

.sort {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem; }
  .sort h5 {
    margin-bottom: 0;
    margin-right: .5rem;
    font-weight: 400; }

.sort-by:hover, .sort-by:focus-within, .react-dropdown-select:hover, .react-dropdown-select:focus-within {
  border-color: #7FC6A6 !important; }

.sort-by:focus, .sort-by:focus-within, .react-dropdown-select:focus, .react-dropdown-select:focus-within {
  box-shadow: none !important; }

.sort-by.react-dropdown-select {
  width: 17rem !important;
  margin: 0; }
  .sort-by.react-dropdown-select .react-dropdown-select-dropdown {
    width: 100%; }
    .sort-by.react-dropdown-select .react-dropdown-select-dropdown .react-dropdown-select-item-selected {
      background: #7FC6A6; }
  .sort-by.react-dropdown-select .react-dropdown-select-content {
    font-size: 0.9rem;
    padding: .2rem .4rem; }

.react-dropdown-select-dropdown {
  background: #282c34 !important; }

.react-dropdown-select-dropdown-handle {
  display: flex;
  align-items: center;
  justify-content: center; }

.react-dropdown-select-item {
  border-bottom: 0 !important;
  font-size: 0.9rem; }
  .react-dropdown-select-item:hover {
    background: #14161a !important; }

input {
  color: #f1f1f1; }

.brake-creator {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column; }
  .brake-creator > div {
    margin-bottom: .5rem; }

.add.button {
  width: 5rem;
  text-align: center;
  margin-right: 0;
  background: #7FC6A6;
  font-weight: 700;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer; }
  .add.button:hover {
    background: #53AE85; }

.selector-wrap {
  flex-shrink: 1;
  margin: 0 0 1.5rem 0; }

.selector {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0 -1.5rem;
  padding: 0.3rem 1.5rem; }
  .selector:hover {
    background: #1f2228; }

.incompatible {
  margin-left: 1rem;
  border-radius: 3px;
  padding: .1rem .2rem;
  color: #14161a;
  font-size: .6rem;
  background: #e53935; }

.tgl-btn {
  outline: 0;
  display: block;
  width: 2rem;
  height: 1.2rem;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 10px; }

.label {
  flex: 1 1; }

.tgl-btn:after,
.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%; }

.tgl-btn:after {
  left: 0; }

.tgl-btn:before {
  display: none; }

.visible .tgl-btn:after {
  left: 50%; }

.tgl-flat .tgl-btn {
  padding: 2px;
  transition: all .2s ease;
  background: #f1f1f1;
  border: 3px solid #b9b9b9;
  border-radius: .4rem; }

.tgl-flat .tgl-btn:after {
  transition: all .2s ease;
  background: #b9b9b9;
  content: "";
  border-radius: 1em; }

.tgl-flat.visible .tgl-btn {
  border: 3px solid #7FC6A6; }

.tgl-flat.visible .tgl-btn:after {
  left: 50%;
  background: #7FC6A6; }

.loader {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader.absolute {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute; }

.recharts-label tspan {
  font-size: .9rem; }

.legend {
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  font-size: .8rem;
  justify-content: center; }
  .legend .item {
    display: flex;
    align-items: center;
    margin: .25rem;
    cursor: pointer;
    padding: .25rem .5rem;
    border-radius: 3px;
    transition: all .1s ease; }
    .legend .item:hover {
      opacity: 0.5;
      transform: translate3d(0, -2px, 0px); }
    .legend .item.disabled {
      opacity: 0.4; }
      .legend .item.disabled:hover {
        opacity: 0.9; }

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite; }

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke-width: 3px;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite; }

@-webkit-keyframes colors {
  0% {
    stroke: #5c6bc0; }
  50% {
    stroke: #26a69a; }
  100% {
    stroke: #ff7043; } }

@keyframes colors {
  0% {
    stroke: #5c6bc0; }
  50% {
    stroke: #26a69a; }
  100% {
    stroke: #ff7043; } }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

.github-corner:hover .octo-arm {
  -webkit-animation: octocat-wave 560ms ease-in-out;
          animation: octocat-wave 560ms ease-in-out; }

@-webkit-keyframes octocat-wave {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(-25deg); }
  40% {
    transform: rotate(10deg); }
  60% {
    transform: rotate(-25deg); }
  80% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes octocat-wave {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(-25deg); }
  40% {
    transform: rotate(10deg); }
  60% {
    transform: rotate(-25deg); }
  80% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0deg); } }

