$bg: #282c34;
$black: #14161a;
$white: #f1f1f1;
$green: #7FC6A6;
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

body {
  background-color: $bg;
  color:$white;
  font-family: 'Lato', sans-serif;
}

strong {
  font-weight: 900;
}

.App {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
  }
}

.block {
  background: $black;
  padding:1rem 1.5rem;
  border-radius: .4rem;
  margin-bottom: 0.5rem;
}

.sidebar {
  @media only screen and (min-width: 800px) {
    p {
      max-width: 280px;
    }
  }
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom:.7rem;
  line-height: 1;
}

a {
  color:$green;
  text-decoration: none;
  font-weight: 700;
  transition:all .2s ease;
  &:hover {
    color:darken($green,10%);
  }
}

p {
  margin-top: 0;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.left {
  margin: 0 0.5rem;
  @media only screen and (min-width: 1200px) {
    width: 20%;
    margin: 0.5rem;
  }
}

.right {
  margin: 0 0.5rem;
  @media only screen and (min-width: 800px) {
    display: flex;
  }
  @media only screen and (min-width: 1200px) {
    width: 80%;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
}

.intro {
  @extend .block;
  overflow: hidden;
  h5 {
    margin-bottom: .3rem;
    color:#979797;
  }
  .small {
    font-size: .8rem;
    color:#979797;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  .hyd {
    color: #5c6bc0;
  }
  .mec {
    color: #26a69a;
  }
  .total {
    color: #ff7043;
  }
  .peak {
    color: #00796b;
  }
  .weight {
    color: #fbc02d;
  }
  .expand {
    background: #000;
    cursor: pointer;
    font-size: 70%;
    margin: 0 -1.5rem -1rem -1.5rem;
    text-align: center;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-right: 1rem;
    }
    svg {
      width: 1rem;
      transition: all .2s ease;
    }
    &.collapsed {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.chart-wrap {
  @extend .block;
  margin-left: 0;
  margin-right: 0;
  flex:1;
  display: flex;
  flex-direction: column;
  align-items:center;
  position: relative;
  .selector {
    display: inline-flex;
  }
  @media only screen and (min-width: 800px) {
    margin-right: 0.5rem;
  }
}

.copyright {
  @extend .block;
  position:relative;
  overflow: hidden;
  p {
    font-size: .8rem;
    margin-bottom: .5rem;
  }
  &.hide-for-large {
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
}

//charts
.custom-tooltip {
  background: $bg;
  border-radius: .4rem;
  padding:1rem 2rem;
  box-shadow:3px 3px 10px rgba(0,0,0,.3);
  h4 {
    margin-top: .5rem;
  }
  h6 {
    margin: 0;
    color:lighten($black, 50%);
  }
  p {
    margin: 0;
  }
  .item-wrap {
    display: flex;
    align-items:flex-start;
    margin: 0 0 .5rem 0;
    .color {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
      border-radius:3px;
    }
  }
}

.default-brakes {
  @extend .block;
  & > .selector-wrap {
    min-height: 8rem;
    position: relative;
  }
}

//dropdown
.mixmatch {
  @extend .block;
  border: 3px solid $green;
  flex:1;
  .selector-wrap {
    margin: 0.5rem 0;
    &:first-of-type {
      margin:0;
    }
  }
}

.dropdown-select {
  padding:.5rem .6rem;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items:flex-start;
  input {
    background:none;
    border:0;
    flex:1;
    width: 80%;
  }
  &:hover {
    background:$black;
  }
  .fluid {
    margin-left:1rem;
    border-radius:3px;
    padding:.1rem .2rem;
    color:$black;
    font-size: .6rem;
    &.DOT {
      background:#ffee58;
    }
    &.Mineral {
      background:#42a5f5;
    }
  }
  .label {
    width: 8rem;
    line-height: 1;
    color:$white;
    padding: 0;
    font-size: 0.9rem;
  }
  &.selected {
    background: $green;
  }
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: $white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: $white;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $white;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $white;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: $white;
}

::placeholder { /* Most modern browsers support this now. */
  color: $white;
}

.react-dropdown-select {
  height: 100%;
}

.sort {
  display: flex;
  align-items:center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  h5 {
    margin-bottom: 0;
    margin-right:.5rem;
    font-weight: 400;
  }
}

.sort-by, .react-dropdown-select {
  &:hover, &:focus-within {
    border-color:$green !important;
  }
  &:focus, &:focus-within {
    box-shadow:none !important;
  }
}
.sort-by.react-dropdown-select {
  width: 17rem !important;
  margin:0;
  // padding:0 !important;
  // height: auto !important;
  // min-height:0;
  // border:0;
  // border-bottom:1px dashed $white;
  .react-dropdown-select-dropdown {
    width: 100%;

    .react-dropdown-select-item-selected {
      background: $green;
    }
  }
  .react-dropdown-select-content {
    font-size: 0.9rem;
    padding:.2rem .4rem;
  }
}

.react-dropdown-select-dropdown {
  background: $bg !important;
}
.react-dropdown-select-dropdown-handle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-dropdown-select-item {
  border-bottom:0 !important;
  font-size: 0.9rem;

  &:hover {
    background: $black !important;
  }
}

// .sort-drop {
//   padding:.5rem .6rem;
//   .label {
//     padding:.2rem .4rem;
//     font-size: 0.9rem;
//   }
//   input.label {
//     padding: 0;
//     line-height: 1;
//   }
// }

input {
  color:$white;
}

.brake-creator {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  & > div {
    margin-bottom: .5rem;
  }
}

.add.button {
  width:5rem;
  text-align: center;
  margin-right: 0;
  background: $green;
  font-weight: 700;
  padding:10px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: #53AE85;
  }
}

//switch
.selector-wrap {
  flex-shrink:1;
  margin:0 0 1.5rem 0;
}

.selector {
  display: flex;
  cursor: pointer;
  // margin:5px 0;
  align-items: center;
  margin: 0 -1.5rem;
  padding: 0.3rem 1.5rem;
  &:hover {
    background: lighten($black, 5%);
  }
}
.incompatible {
  margin-left:1rem;
  border-radius:3px;
  padding:.1rem .2rem;
  color:$black;
  font-size: .6rem;
  background:#e53935;
}

.tgl-btn {
  outline: 0;
  display: block;
  width: 2rem;
  height: 1.2rem;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-right:10px;
}
.label {
  flex:1;
}
.tgl-btn:after,
.tgl-btn:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }
    
.tgl-btn:after {
      left: 0;
    }
    
.tgl-btn:before {
      display: none;
    }

.visible .tgl-btn:after {
  left: 50%;
}

.tgl-flat .tgl-btn {
  padding: 2px;
  transition: all .2s ease;
  background: $white;
  border: 3px solid #b9b9b9;
  border-radius: .4rem;
}
.tgl-flat .tgl-btn:after {
  transition: all .2s ease;
  background: #b9b9b9;
  content: "";
  border-radius: 1em;
}
.tgl-flat.visible .tgl-btn {
  border: 3px solid $green;
}
.tgl-flat.visible .tgl-btn:after {
  left: 50%;
  background: $green;
}


.loader {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &.absolute {
    top:0;
    left:0;
    right: 0;
    bottom:0;
    position:absolute;
  }
}

.recharts-label {
  tspan {
    font-size: .9rem;
  }
}

//legend
.legend {
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  font-size: .8rem;
  justify-content: center;
  .item {
    display: flex;
    align-items:center;
    margin:.25rem;
    cursor: pointer;
    padding: .25rem .5rem;
    border-radius: 3px;
    transition: all .1s ease;
    &:hover {
      opacity: 0.5;
      transform: translate3d(0, -2px, 0px);
    }
    &.disabled {
      opacity: 0.4;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke-width:3px;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% { stroke: #5c6bc0; }
  50% { stroke: #26a69a; }
  100% { stroke: #ff7043; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}

//github wave
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-25deg);
    }

    40% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(-25deg);
    }

    80% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}